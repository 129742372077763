import { format } from "date-fns";

export const dateDMY = (dateStr) => {
  let date = new Date(dateStr);
  let usDate = date.toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  const formattedDate = format(usDate, "MM.dd.yy");
  return formattedDate;
};

export const dateDMYTime = (dateStr) => {
  let date = new Date(dateStr);
  let usDate = date.toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  const formattedDate = format(usDate, "MM/dd/yyyy HH:mm a");
  return formattedDate;
};

export const dateDMYSpaced = (dateStr) => {
  let date = new Date(dateStr);
  let usDate = date.toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  const formattedDate = format(usDate, "MM/dd/yy");
  return formattedDate;
};
