import React from "react";
import {
  Button
} from "react-bootstrap";
import { ReactComponent as AGSearchIcon } from "../../assets/images/AGSearchIcon.svg";
import { saLandingPlaceholder } from "../../constants/firm/labels";
import { ReactComponent as SearchXIcon } from "../../assets/images/SearchXIcon.svg";

export default function FirmPageTitleBar({
  title,
  hasButton,
  buttonText,
  navigateTo,
  width,
  hasInpuField,
  searchText,
  handleSearchChange,
  handleClearSearch,
}) {

  return (
    <div className="firm-title d-flex justify-content-between align-items-center">
      <div className="firm-sub-title">{title}</div>
      <div className="d-flex justify-content-between align-items-center">
        {/* Search Input Field */}
        {hasInpuField && (
          <div className="sa-search-container d-none d-md-block">
            <div className="search-icon">
              <AGSearchIcon />
            </div>
            <div className="search-input-field">
              <input
                type="text"
                placeholder={saLandingPlaceholder.saSearchText}
                value={searchText}
                onChange={handleSearchChange}
                id="search-firm-input-field"
                data-testid="grid-search-input"
              />
            </div>
            {searchText && (
              <div onClick={handleClearSearch} className="clear-search-button">
                <SearchXIcon />
              </div>
            )}
          </div>
        )}

        {/* Add Button */}
        {hasButton && (
          <div className="ps-3">
            <Button
              style={{ width: `${width}` }}
              className="custom-button btn-standard btn-new-firm btn-add-client"
              type="button"
              onClick={navigateTo}
              data-testid="new-firm-onboard-button"
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
