import React, { useState } from "react";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import {
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Button,
  Accordion,
  Card,
} from "react-bootstrap";
import { ReactComponent as KeyboardLeftArrow } from "../../assets/images/keyboard_arrow_left.svg";
import { ChevronDown } from "react-bootstrap-icons";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down-doc-part.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right.svg";

import { shareReceiveBtn } from "../../constants/client/labels";
import {
  client_bankinformation,
  client_bulk_file_share,
  client_personal_bank_details,
  client_personaluse,
  client_receivefiles,
  client_request_bank_details,
} from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useSelector } from "react-redux";

export default function ClientTitleBar({
  items,
  title,
  navigateToPrev,
  hasBackButton,
  isMobile,
  createFolderCallback,
}) {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState();
  const handleNavigate = () => {
    navigate(client_personaluse);
  };
  const { userInfo } = useSelector((state) => state.auth);
  const isTwoLevel = userInfo.firmTypeId === 2;
  const CustomToggle = ({ children, eventKey, activeKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey);
    const isActive = activeKey && activeKey.toString() === eventKey;

    return (
      <div
        onClick={decoratedOnClick}
        className="d-flex justify-content-between align-items-center"
      >
        <div className="d-flex align-items-center">{children}</div>
        <div className="arrow-icon" style={{ width: "20px" }}>
          {isActive ? <ArrowDown /> : <ArrowRight />}
        </div>
      </div>
    );
  };

  const dropdownComp = (
    <div>
      <span className="me-1">{shareReceiveBtn} </span>
      <ChevronDown
        size={16}
        className="dropbtn-bulkshare"
        color="#fff"
        data-testid="share-rec-chevronDown-icon"
      />
    </div>
  );

  return (
    <div className="d-flex justify-content-between align-items-center pb-2 flex-column flex-md-row flex-lg-row">
      <div>
        {items !== false && <BreadCrumb items={items} />}
        {hasBackButton && (
          <span className="keyboard-left-arrow pe-2" onClick={navigateToPrev}>
            <KeyboardLeftArrow />
          </span>
        )}
        <span className="page-title">{title}</span>
      </div>
      <div className="d-flex justify-content-between flex-column flex-md-row flex-lg-row client-title-bar-buttons">
        <div className="pd-mb">
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip
                id="personal-use-tooltip"
                className="tooltip-personaluse"
              >
                This feature allows you to designate certain folders and files
                as Personal. In the event of your death, these files will be
                permanently deleted and will not be accessible to any party,
                including your executor. However, unless specifically indicated
                otherwise, these files may be accessed by your designated
                professional during your lifetime.
              </Tooltip>
            }
          >
            <Button
              style={{ width: isMobile ? "12.1875rem" : "8.5rem" }}
              className="custom-button btn-standard btn-new-firm mx-2"
              type="button"
              onClick={handleNavigate}
              data-testid="new-firm-onboard-button"
            >
              Personal Use
            </Button>
          </OverlayTrigger>
        </div>
        <div className="pd-mb">
          <OverlayTrigger
            placement="bottom"
            //trigger="click"
            overlay={
              <Tooltip id="new-folder-tooltip" className="tooltip-new-folder">
                Click here to create a new folder
              </Tooltip>
            }
          >
            <Button
              style={{ width: isMobile ? "12.1875rem" : "8.5rem" }}
              className={`custom-button btn-standard btn-new-firm new-folder mx-2 mb-1 me-lg-3 me-lg-3 me-md-3`}
              type="button"
              onClick={createFolderCallback}
              data-testid="new-folder-button"
            >
              + <span className="ps-2">New Folder</span>
            </Button>
          </OverlayTrigger>
        </div>
        <DropdownButton
          id="dropdown-basic-button"
          title={dropdownComp}
          data-testid="dropdown-share-rec-button"
          style={{ width: isMobile ? "12.1875rem" : "10rem" }}
        >
          <Accordion
            activeKey={activeKey}
            onSelect={(selectedIndex) => setActiveKey(selectedIndex)}
          >
            {isTwoLevel ? (
              <>
                <Card key="0" className="custom-card-filetransfer">
                  <Card.Header className="custom-card-header-filetransfer">
                    <CustomToggle eventKey="0" activeKey={activeKey}>
                      <div className="d-flex">
                        <div className="ps-1 pe-4">Bank Transactions</div>
                      </div>
                    </CustomToggle>
                  </Card.Header>

                  <Accordion.Collapse eventKey={`0`}>
                    <Card.Body className="custom-card-body-filetransfer">
                    <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip
                            id="tooltip-perosnal-bank-details"
                            className="custom-tooltip-bulkshare"
                          >
                            Enable you to add and view your personal bank
                            details securely, and share them easily with others
                            when needed
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item href={client_personal_bank_details}>
                          Personal Bank Details
                        </Dropdown.Item>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip
                            id="tooltip-share-bank-details"
                            className="custom-tooltip-bulkshare"
                          >
                            Enables you to share your bank details to anyone
                            outside or within the <b>FamVault</b> platform in
                            the most secure way
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item
                          href={client_personal_bank_details}
                          data-testid="receive-files-item"
                        >
                          Send Bank Details
                        </Dropdown.Item>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip
                            id="tooltip-share-bank-details"
                            className="custom-tooltip-bulkshare"
                          >
                            Enables you to receive your bank details to anyone
                            outside or within the <b>FamVault</b> platform in
                            the most secure way
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item
                          href={client_request_bank_details}
                          data-testid="receive-files-item"
                        >
                          Request Bank Details
                        </Dropdown.Item>
                      </OverlayTrigger>
                      <Dropdown.Item href={client_bankinformation}>
                        Initiate Money Transfer
                      </Dropdown.Item>
                      {/* </OverlayTrigger> */}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card key="1" className="custom-card-filetransfer">
                  <Card.Header className="custom-card-header-filetransfer">
                    <CustomToggle eventKey="1" activeKey={activeKey}>
                      <div className="d-flex">
                        <div className="ps-1 pe-4">File Transfer</div>
                      </div>
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={`1`}>
                    <Card.Body className="custom-card-body-filetransfer">
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip
                            id="tooltip-share-bank-details"
                            className="custom-tooltip-bulkshare"
                          >
                            Enable users to request other professionals to
                            upload documents on their behalf
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item
                          href={client_receivefiles}
                          data-testid="receive-files-item"
                        >
                          Request files from Other Professionals
                        </Dropdown.Item>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip
                            id="tooltip-share-bank-details"
                            className="custom-tooltip-bulkshare"
                          >
                            This feature allows you to transfer files/folders in
                            bulk with anyone within & outside the platform.
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item href={client_bulk_file_share}>
                          Bulk File transfer
                        </Dropdown.Item>
                      </OverlayTrigger>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </>
            ) : (
              <Card key="0" className="custom-card-filetransfer">
                <Card.Header className="custom-card-header-filetransfer">
                  <CustomToggle eventKey="0" activeKey={activeKey}>
                    <div className="d-flex">
                      <div className="ps-1 pe-4">File Transfer</div>
                    </div>
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={`0`}>
                  <Card.Body className="custom-card-body-filetransfer">
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip
                          id="tooltip-share-bank-details"
                          className="custom-tooltip-bulkshare"
                        >
                          Enable users to request other professionals to upload
                          documents on their behalf
                        </Tooltip>
                      }
                    >
                      <Dropdown.Item
                        href={client_receivefiles}
                        data-testid="receive-files-item"
                      >
                        Request files from Other Professionals
                      </Dropdown.Item>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip
                          id="tooltip-share-bank-details"
                          className="custom-tooltip-bulkshare"
                        >
                          This feature allows you to transfer files/folders in
                          bulk with anyone within & outside the platform.
                        </Tooltip>
                      }
                    >
                      <Dropdown.Item href={client_bulk_file_share}>
                        Bulk File transfer
                      </Dropdown.Item>
                    </OverlayTrigger>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
          </Accordion>
        </DropdownButton>
      </div>
    </div>
  );
}
