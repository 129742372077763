import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

function BankShareConfirmationPopup({ showModal, setShowCallback, setYesCallback, content, contentClass }) {
  const handleClose = () => setShowCallback(false);
  const handleClickYes = () => setYesCallback(true);
  return (
    <>
      <Modal
        id="modal-popup-container-type-2"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        data-testid="modal-popup-type2"
      >
        <Modal.Header className="modal-custom-header" closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content-container text-center p-lg-4 p-md-4 p-sm-4 p-2">
            <div><b>{content}</b></div>
              <div className="modal-button-section mt-4 mb-5">
              <Button onClick={handleClickYes} className="custom-popup-button btn-yes left-radius" data-testid="yes-button">
                Yes
              </Button>
              <Button
                onClick={handleClose}
                className="custom-popup-button btn-no right-radius"
                 data-testid="no-button"
              >
                No
              </Button>
            </div>         
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

BankShareConfirmationPopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowCallback: PropTypes.func,
  content: PropTypes.string,
};

export default BankShareConfirmationPopup ;
