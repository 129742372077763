import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import AttorneyTitleBar from "../attorney/AttorneyTitleBar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  client_add_bank_details,
  client_LandingPage,
  client_personal_bank_details,
  client_share_bank_details,
  client_view_bank_details,
} from "../../constants/routes";
import AgGrid from "../table/AgGrid";
import { ReactComponent as ShareBank } from "../../assets/images/ShareBank.svg";
import { ReactComponent as Eye } from "../../assets/images/eye.svg";
import { ReactComponent as DeleteAction } from "../../assets/images/DeleteAction.svg";
import { bankDetailsLabel, clientTitle } from "../../constants/client/labels";
import { toast } from "react-toastify";
import ModalPopupType8 from "../modal/ModalPopupType8";
import {
  useDeleteBankDetailsMutation,
  useGetAllBankDetailsQuery,
} from "../../slices/wireTransferApiManagementSlice";
import Loader from "../Loader/Loader";

export default function BankDetailsLandingPage({ refetchData }) {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const [rowData, setRowData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const ssoResponse =
    sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse);
  const isSSOLogin = ssoResponse
    ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful
    : false;

  const { data, isLoading } = useGetAllBankDetailsQuery(userInfo?.userId, {
    refetchOnMountOrArgChange: true,
  });

  const [deleteBankDetails, { isLoading: deletBankDetailsLoading }] =
    useDeleteBankDetailsMutation();

  useEffect(() => {
    if (data && Array.isArray(data?.data)) {
      setRowData(data?.data);
    } else {
      setRowData([]);
    }
  }, [data]);

  let empnameWidth = 250;
  let empnameMinWidth = 200;
  if (window.innerWidth < 576) {
    empnameWidth = 100;
    empnameMinWidth = 140;
  }

  const [items] = useState([
    {
      id: "item-1",
      link: client_LandingPage,
      content: clientTitle,
      active: false,
    },
    {
      id: "item-2",
      link: client_personal_bank_details,
      content: "Personal Bank Details",
      active: true,
    }
  ]);

  const getRowStyle = () => {
    return { borderBottom: "unset" };
  };

  const viewBankDetails = (event) => {
    navigate(client_view_bank_details, {
      state: {
        id: event?.data,
        userId: userInfo?.userId,
      },
    });
  };

  const handleShareButton = (event) => {
    navigate(client_share_bank_details, {
      state: {
        id: event?.data,
      },
    });
  };

  const GridFullName = (params) => {
    const { value } = params;

    if (value) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
        >
          <div className="truncate-tooltip grid-firm-name-anchor">{value}</div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div className="truncate-tooltip grid-firm-name-anchor">{value}</div>
      );
    }
  };

  const ShareIcon = (event) => {
    return (
      <div className="grid-center-aligned-text">
        <div className="share-button" onClick={() => handleShareButton(event)}>
          <ShareBank />
        </div>
      </div>
    );
  };

  const handleClickModalPopup = (params) => {
    setShowModal(true);
    setDeleteId(params?.data?.bankDetailId);
  };

  const GridButton = (params) => {
    return (
      <div className="d-flex justify-content-center">
        <div className="sa-action-button mx-2">
          <div
            className="action-button share-button"
            data-testid="view-button"
            onClick={() => viewBankDetails(params)}
          >
            <Eye />
          </div>
        </div>
        <div className="sa-action-button mx-2">
          <div
            className="action-button share-button"
            data-testid="delete-button"
            onClick={() => handleClickModalPopup(params)}
          >
            <DeleteAction />
          </div>
        </div>
      </div>
    );
  };

  const [columnDefsNonSSO] = useState([
    {
      headerName: bankDetailsLabel.accountName,
      field: "accountNumber",
      width: empnameWidth,
      minWidth: empnameMinWidth,
      pinned: "left",
      cellRenderer: (params) => {
        const firstName = params?.data?.firstName || '';
        const lastName = params?.data?.lastName || '';
        const fullName = `${firstName} ${lastName}`;
        return <GridFullName value={fullName} />;
      },
      onCellClicked: (event) => viewBankDetails(event),
    },
    { headerName: "Account Number", field: "accountNumber", minWidth: 350 },
    { headerName: "Bank Name", field: "bankName", minWidth: 250 },
    { headerName: "Branch Name", field: "branchName", minWidth: 250 },
    { headerName: "Account Type", field: "accountType", minWidth: 180 },
    {
      headerName: "Share",
      field: "share",
      cellRenderer: ShareIcon,
      minWidth: 100,
      width: 50,
      headerClass: "center-aligned-header",
    },
    {
      headerName: "Actions",
      field: "action",
      width: 200,
      cellRenderer: GridButton,
      minWidth: 200,
      headerClass: "center-aligned-header",
    },
  ]);
  const [columnDefsSSO] = useState([
    {
      headerName: bankDetailsLabel.accountName,
      field: "accountName",
      width: empnameWidth,
      minWidth: empnameMinWidth,
      pinned: "left",
      cellRenderer: (params) => {
        const firstName = params?.data?.firstName || '';
        const lastName = params?.data?.lastName || '';
        const fullName = `${firstName} ${lastName}`;
        return <GridFullName value={fullName} />;
      },
    },
    { headerName: "Account Number", field: "accountNumber", minWidth: 350 },
    { headerName: "Bank Name", field: "bankName", minWidth: 180 },
    { headerName: "Branch Name", field: "branchName", minWidth: 180 },
    { headerName: "Account Type", field: "accountType", minWidth: 180 },
    {
      headerName: "Share",
      field: "share",
      minWidth: 50,
    },
    {
      headerName: "Action",
      field: "action",
      minWidth: 100,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      suppressMovable: false,
      sortable: false,
      wrapHeaderText: true,
      wrapText: true,
      headerClass: "custom-header",
      resizable: false,
      lockPosition: true,
      filter: false,
      // minWidth: 140,
    };
  }, []);

  const handlePrevPage = () => {
    navigate(client_LandingPage, {
      state: {
        id: userInfo?.firmTypeId,
      },
    });
  };

  const handleAddBankDetails = () => {
    navigate(client_add_bank_details, {
      state: {
        id: userInfo?.firmTypeId,
        page: "bankDetails",
      },
    });
  };

  const handleYesClicked = async () => {
    setShowModal(false);
    try {
      const res = await deleteBankDetails({
        bankDetailId: deleteId,
        userId: userInfo?.userId,
      }).unwrap();
      if (res?.successful) {
        toast(
          <div>
            <div className="upload mt-2">{res?.message}</div>
          </div>,
          {
            autoClose: true,
            closeOnClick: false,
            theme: "light",
            closeButton: true,
            hideProgressBar: true,
            className: "custom-toast-video-sigining",
          }
        );
        refetchData();
      } else {
        toast.error(res?.errorMessage);
      }
    } catch (error) {
      toast.error(error?.errorMessage);
    }
    // navigate(client_personal_bank_details);
  };

  const isButtonDisabled = rowData.length >= 5 || rowData.length === 5;

  return (
    <>
      <div
        className="superadmin-container attorney-edit-client-page"
        data-testid="na-firmadmin-addclient"
      >
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-0 px-lg-4 px-md-4 wire-transfer"
            data-testid="sa-addemployee-page"
          >
            <AttorneyTitleBar
              items={items}
              hasButton={!isSSOLogin}
              buttonText={bankDetailsLabel.addBankAccount}
              width="90px"
              title={bankDetailsLabel.allAccounts}
              navigateTo={handleAddBankDetails}
              navigateToPrev={handlePrevPage}
              hasBackButton={true}
              buttonDisabled={isButtonDisabled}
            />
          </div>
        </Container>

        <div className="firm-page-outer-container firm-page-add-outer-container">
          <div className="firm-page-container employee-form-container">
            <Container fluid className="px-lg-4">
              <div className="px-2 pb-3 px-lg-4 px-md-4">
                <Row className="m-10 mt-4">
                  <Col lg={12} xs={12}>
                    <AgGrid
                      rowData={rowData}
                      columnDefs={isSSOLogin ? columnDefsSSO : columnDefsNonSSO}
                      defaultColDef={defaultColDef}
                      getRowStyle={getRowStyle}
                    />
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
        <ModalPopupType8
          setShowCallback={() => setShowModal(false)}
          setYesCallback={handleYesClicked}
          headerTitle={"Delete Item"}
          content={bankDetailsLabel.deletePopup}
          showModal={showModal}
        />
      </div>
      {(isLoading || deletBankDetailsLoading) && <Loader />}
    </>
  );
}
