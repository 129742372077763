import { configureStore } from "@reduxjs/toolkit";
import { getFirmOnboardingValuesApi } from "./slices/getFirmOnboardingValuesApi";
import { getFirmOnboardingFirmTypeApi } from "./slices/getFirmOnBoardingFirmTypeApi";
import { firmApiSlice } from "./slices/firmApiSlice";
import { userApiSlice } from "./slices/userApiSlice";
import { loginApiSlice } from "./slices/loginApiSlice";
import authReducer from "./slices/authSlice";
import { getAllFirmDetailsSlice } from "./slices/getAllFirmDetailsSlice";
import { bulkUploadApiSlice } from "./slices/bulkUploadApiSlice";
import { documentApiSlice } from "./slices/documentApiSlice";
import { ssoApiSlice } from "./slices/ssoApiSlice";
import { stackHolderApiSlice } from "./slices/stackHolderApiSlice";
import stackHolderReducer from "./slices/stackHolderSlice";
import { deathClaimApiSlice } from "./slices/deathClaimApiSlice";
import { executorApiSlice } from "./slices/executorApiSlice";
import { reportApiSlice } from "./slices/reportApiSlice";
import { notificationApiSlice } from "./slices/notificationSlice";
import addClientReducer from "./slices/attorneyAddClientSlice";
import { medicalProfessionalApiSlice } from "./slices/medicalProfessionalApiSlice";
import additionalProfReducer from "./slices/additionalProfessionalSlice";
import { additionalProfApiSlice } from "./slices/additionalProfApiSlice";
import { additionalProUserApiSlice } from "./slices/additionalProfUserApiSlice";
import { wireTransferDownloadApiSlice } from "./slices/wireTransferDownloadApiSlice";
import { wireTransferUploadApiSlice } from "./slices/wireTransferUploadApiSlice";
import shareReceiveBankDetailsReducer from "./slices/shareReceiveBankDetailsSlice";
import { gaApiSlice } from "./slices/gaApiSlice";

import { personalUseApiSlice } from "./slices/personalUseApiSlice";
import { noClaimApiSlice } from "./slices/noClaimSlice";
import { documentApiSliceV2 } from "./slices/documentApiSliceV2";
import { folderApiSlice } from "./slices/folderApiSlice";
import { wireTransferApiSlice } from "./slices/wireTransferApiSlice";
import { wireTransferAuthSlice } from "./slices/wireTransferAuthSlice";
const store = configureStore({
  reducer: {
    [ssoApiSlice.reducerPath]: ssoApiSlice.reducer,
    [stackHolderApiSlice.reducerPath]: stackHolderApiSlice.reducer,
    [userApiSlice.reducerPath]: userApiSlice.reducer,
    [loginApiSlice.reducerPath]: loginApiSlice.reducer,
    [deathClaimApiSlice.reducerPath]: deathClaimApiSlice.reducer,
    [firmApiSlice.reducerPath]: firmApiSlice.reducer,
    [getFirmOnboardingValuesApi.reducerPath]:
      getFirmOnboardingValuesApi.reducer,
    [getFirmOnboardingFirmTypeApi.reducerPath]:
      getFirmOnboardingFirmTypeApi.reducer,
    auth: authReducer,
    [getAllFirmDetailsSlice.reducerPath]: getAllFirmDetailsSlice.reducer,
    [bulkUploadApiSlice.reducerPath]: bulkUploadApiSlice.reducer,
    [documentApiSlice.reducerPath]: documentApiSlice.reducer,
    [stackHolderApiSlice.reducerPath]: stackHolderApiSlice.reducer,
    stackholder: stackHolderReducer,
    [reportApiSlice.reducerPath]: reportApiSlice.reducer,
    [executorApiSlice.reducerPath]: executorApiSlice.reducer,
    [notificationApiSlice.reducerPath]: notificationApiSlice.reducer,
    [medicalProfessionalApiSlice.reducerPath]:
      medicalProfessionalApiSlice.reducer,
    addClient: addClientReducer,
    [additionalProfApiSlice.reducerPath]: additionalProfApiSlice.reducer,
    [additionalProUserApiSlice.reducerPath]: additionalProUserApiSlice.reducer,
    [personalUseApiSlice.reducerPath] : personalUseApiSlice.reducer,
    [wireTransferDownloadApiSlice.reducerPath]:
      wireTransferDownloadApiSlice.reducer,
    [gaApiSlice.reducerPath]: gaApiSlice.reducer,
    [wireTransferUploadApiSlice.reducerPath]:
      wireTransferUploadApiSlice.reducer,
    shareReceiveBankDetails: shareReceiveBankDetailsReducer,
    addprof:additionalProfReducer,
    [noClaimApiSlice.reducerPath]: noClaimApiSlice.reducer,
    [documentApiSliceV2.reducerPath]: documentApiSliceV2.reducer,
    [folderApiSlice.reducerPath]: folderApiSlice.reducer,
    [wireTransferApiSlice.reducerPath]: wireTransferApiSlice.reducer,
    [wireTransferAuthSlice.reducerPath]: wireTransferAuthSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      ssoApiSlice.middleware,
      stackHolderApiSlice.middleware,
      userApiSlice.middleware,
      loginApiSlice.middleware,
      deathClaimApiSlice.middleware,
      getFirmOnboardingValuesApi.middleware,
      getFirmOnboardingFirmTypeApi.middleware,
      firmApiSlice.middleware,
      getAllFirmDetailsSlice.middleware,
      bulkUploadApiSlice.middleware,
      documentApiSlice.middleware,
      stackHolderApiSlice.middleware,
      reportApiSlice.middleware,
      executorApiSlice.middleware,
      notificationApiSlice.middleware,
      medicalProfessionalApiSlice.middleware,
      additionalProfApiSlice.middleware,
      additionalProUserApiSlice.middleware,
      personalUseApiSlice.middleware,
      wireTransferDownloadApiSlice.middleware,
      wireTransferUploadApiSlice.middleware,
      gaApiSlice.middleware,
      noClaimApiSlice.middleware,
      documentApiSliceV2.middleware,
      folderApiSlice.middleware,
      wireTransferApiSlice.middleware,
      wireTransferAuthSlice.middleware,
    ),
  devTools: true,
});

export default store;
