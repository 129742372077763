import React from "react";
import { clientRelationship } from "../../../constants/attorney/mockData";
import { Alert, Container, Row, Col } from "react-bootstrap";
import { useGetStakeHolderDetailsQuery } from "../../../slices/stackHolderApiManagmentSlice";
import { useSelector } from "react-redux";

export default function ViewFamilyDetails() {
  const { userInfo } = useSelector((state) => state.auth);
  const userId  =  userInfo?.role === "EXE" ? userInfo?.clientId : userInfo?.userId
  const { data, error } = useGetStakeHolderDetailsQuery(userId, {
    skip: userId === undefined,
  });
  const dateString = data?.familyMember?.dateOfBirth || "";
  let formattedDate = "";
  if (dateString) {
    // const date = new Date(dateString);
    // const day = date.getDate();
    // const month = date.getMonth() + 1;
    // const year = date.getFullYear();
    // formattedDate = `${month < 10 ? "0" : ""}${month}-${
    //   day < 10 ? "0" : ""
    // }${day}-${year}`;
    formattedDate = dateString.split('T')[0]
  }
  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data}
        </Alert>
      </Container>
    );
  }

  return (
    <>
      {data?.familyMember?.firstName !== undefined ? 
            <Container className="employee-view-container pt-3 ps-3">
              <div className="view-details family-details">
                <Row className="align-items-start">
                  <Col lg={5} md={6} sm={6} xs={12} className="pb-3">
                    <div className="firm-view-label">Name</div>
                    <div className="firm-view-content">
                      {data?.familyMember?.name ? data?.familyMember?.name : "--"}
                    </div>
                  </Col>
                  <Col lg={5} md={6} sm={6} xs={12} className="pb-3">
                    <div className="firm-view-label">DOB</div>
                    <div className="firm-view-content">
                      {data?.familyMember?.dateOfBirth ? formattedDate : "--"}
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-start">
                  <Col lg={5} md={6} sm={6} xs={12} className="pb-3">
                    <div className="firm-view-label">Relationship</div>
                    <div className="firm-view-content">
                      {data?.familyMember?.relationship
                        ? clientRelationship.filter((ele) => ele.id === data?.familyMember?.relationship)[0]?.label
                        : "--"}
                    </div>
                  </Col>
                  <Col lg={5} md={6} sm={6} xs={12} className="pb-3">
                    <div className="firm-view-label">Email</div>
                    <div className="firm-view-content">
                      {data?.familyMember?.emailId
                        ? data?.familyMember?.emailId
                        : "--"}
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-start">
                  <Col lg={5} md={6} sm={6} xs={12} className="pb-3">
                    <div className="firm-view-label">Mobile Phone</div>
                    <div className="firm-view-content">
                      {data?.familyMember?.phoneNumber
                        ? data?.familyMember?.phoneNumber
                        : "--"}
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
      :(
      <Container className="employee-view-container pt-3 ps-3" data-testid="no-data">
        No Data found
      </Container>
      )}
    </>
  );
}
