export const formPlaceholder = {
  firmTypeEdit: "Type of Firm",
  firmName: "Firm Name",
  firmRegNumber: "Employer Identification Number",
  firmType: "Select the Firm Type",
  acctManagerName: "Account Manager’s Name",
  noOfEmployess: "Select Number of Employees",
  haveSSO: "Do you have an SSO?",
  domainName: "Domain Name",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  phone: "Mobile Phone",
  mobilephone: "Mobile Phone",
  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  zip: "Zip",
  cityList: "Select City",
  city: "City",
  state: "State",
  country: "Country",
  selectPlan: "Select the Plan",
  knowAbout: "How did you know about us?",
  superAdminTitle: "Super Admin Details",
  saFirstName: "First Name",
  saLastname: "Last Name",
  saEmail: "Email",
  saPhone: "Mobile Phone",
  submitBtnText: "Submit",
  saRegeneratePW: "Regenerate password",
  updateBtnText: "Update Details",
  cancelBtnText: "Cancel",
  backButton: "Back",
  enterNewProfessional: "Enter New Professional Email",
  searchBtnText: "Search",
  dob: "DOB",
  addFamily: "Add your family details",
  mothersMaidenName:"Mother’s Maiden Name"
};

export const firmLandingPlaceholder = {
  threeTierOrgButtonText: "3 Tier Firms", 
  twoTierOrgButtonText: "2 Tier Firms",
  firmName: "Firm Name", 
  plan: "Plan",
  accountActivation: "Account Activation",
  renewalDate: "Renewal Date",
  numberOfAccounts: "Number of Accounts",
  willsUploaded: "Wills Uploaded",
  subStatus: "Status",
};

export const saLandingPlaceholder = {
  saBulkUpload: "Bulk Upload",
  saAddEmployee: "Add Professional",
  superAdminTableLabel: "Number of Employee",
  superAdminTableLabelPlural: "Number of Employees",
  fullName: "Employee Name",
  email: "Email",
  phone: "Mobile Phone",
  numberOfClients: "Number of Members",
  attorneyAction: "Action",
  saSearchText: "Search by Name, Email & Mobile Phone",
  saAddClient: "Add Member",
  saClientLabel: "Number of Member",
  saClientLabelPlural: "Number of Members",
};
export const firmAttorneylabel = {
  deleteProfessional: "Deletion",
  deleteConfirmText: "Are you sure you want to delete Professional account?",
  deleteAssignProfessionalText:
    "Professional(s) cannot be deleted as there are associated members. Please move the members before attempting to delete.",
  changeOfProfessional: "Professional Change",
  assignAttorneySuccess:
    "Change of Professional for the selected Members has been done successfully.  An email has been sent to the Professional(s) and the respective Members.",
  changeRequest:
    "Change request of Professional sent successfully. Please check your Email.",
};

export const clientPlaceholder = {
  fullName: "Member Name",
  email: "Email",
  phone: "Mobile Phone",
  clientAction: "Action",
  clientTableLabel: "Member Details",
  cliSearchText: "Search by Name, Email & Mobile Phone",
  selectAll: "Select All",
};

export const reportPlaceholder = {
  firmName: "Firm Name",
  firmType: "Firm Type",
  totalAccount: "Total Accounts",
  willsUploaded: "Wills Uploaded",
  employeeSize: "Employee Size",
  activationDate: "Activation Date",
  expiryDate: "Expiry Date",
  capacityUsed: "Capacity Used",
  activeUsers: "Active Users",
  avgDocPerUser: "Average Document Per User",
  avgCapacityUtilPerUser: "Average Capacity Utilization Per User",
  firmEnrolled: "Firms Enrolled",
  threeTierFirm: "3 Tier Firms",
  twoTierFirm: "2 Tier Firms",
  totalWillsUploaded: "Total Wills Uploaded",
  capacityUtilization: "Capacity Utilization",
  documentsPerUser: "Average Document Per User",
  capacityUtilizationPerUser: "Average Capacity Utilization Per User",
  downloadAsPDF: "Download as PDF",
  downloadAsCSV: "Download as CSV",
};
