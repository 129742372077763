import { ssoApiSlice } from "./ssoApiSlice";

export const ssoManagementSlice = ssoApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    ssoauthorize: builder.query({
      query: (data) => ({
        url: `/authorize?domainName=${data}`,
        method: "GET",
      }),
    }),   
    ssoexchange: builder.query({
      query: (data) => ({
        url: `/exchange?code=${data}`,
        method: "GET",
      }),
    }), 
    ssousersignin: builder.query({
      query: (data) => ({
        url: `/ssoUserSignIn?code=${data}`,
        method: "GET",
      }),
    }),
    getGeoCodesZipCode: builder.query({
      query:(zip) => ({
        url:"/geo-code",
        method:"GET",
        headers: {
          zipCode: `${zip}`,
        },
      })
    }),
    getSecurityKey: builder.query({
      query:() => ({
        url:"/wt-key",
        method:"GET",
      })
    })
  }),
});

export const {
  useLazySsousersigninQuery,
  useLazySsoexchangeQuery,
  useLazySsoauthorizeQuery,
  useGetGeoCodesZipCodeQuery,
  useGetSecurityKeyQuery, 
} = ssoManagementSlice;
