import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FirmAdminLandingPage from "../pages/firmadmin/FirmAdminLandingPage";
import NAFirmAdminLandingPage from "./nonAttorneyFirmSuperAdmin/NAFirmAdminLandingPage";
import NotFound from "../components/NotFound/NotFound";
import {
  firmsuperadmin_LandingPage
} from "../constants/routes";
import { setDetails } from "../slices/shareReceiveBankDetailsSlice";

export default function SuperAdminRenderPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const queryParameters = new URLSearchParams(window.location.search);
  const identifierID = queryParameters.get("identifier");
  //const requestType = queryParameters.get("requestType");
  const urlType = queryParameters.get("type");
  useEffect(() => {
    if (identifierID && urlType && (urlType === "addProf")) {
      dispatch(setDetails({ identifierID, requestType:urlType }));
      navigate(`${firmsuperadmin_LandingPage}/additionproflanding?identifier=${identifierID}&type=${urlType}`);
    }
    if (identifierID && urlType && (urlType === "deathClaim")) {
      dispatch(setDetails({ identifierID, requestType:urlType }));
      navigate(`${firmsuperadmin_LandingPage}/reporterlanding?identifier=${identifierID}&type=${urlType}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  let renderComponet;
  switch (location?.state?.id || location?.state?.res || userInfo?.firmTypeId) {
    case 1:
      renderComponet = <FirmAdminLandingPage />;
      break;
    case 2:
      renderComponet = <NAFirmAdminLandingPage />;
      break;
    default:
      renderComponet = <NotFound />;
      break;
  }
  

  return (
    <>
      <div
        className="view-people-info-container"
        data-testid="super-admin-render-page"
      >
        {renderComponet}
      </div>
    </>
  );
}
