import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
// import { signOutApiCall } from "./authSlice";
// import { toast } from "react-toastify";

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL_BANKINGSERVICE}/api/v1/transactions`,
});

// const baseQueryWithAuth = async (args, api, extraOptions) => {
//   const result = await baseQuery(args, api, extraOptions);
//   if (result.error && result.error.status === 401) {
//     api.dispatch(signOutApiCall());
//     toast.error(`Signed out successfully due to token expiration!`);
//   }
//   return result;
// };

export const wireTransferAuthSlice = createApi({
  reducerPath: "wireTransferAuthSlice",
  baseQuery: baseQuery,
  tagTypes: ["wireTransferAuthSlice"],
  endpoints: (builder) => ({}),
});
