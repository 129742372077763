export const validation = {
  firmName: {
    required: "Enter Firm Name",
  },
  firmRegNumber: {
    required: "Enter Employer Identification Number",
    firmRegisterationNumberRules: "This value must contain alphanumeric characters",
  },
  firmType: {
    required: "Select the Firm Type",
  },
  acctManagerName: {
    required: "Enter Account Manager's Name",
    rule: "Account Manager's Name must be alphabets"
  },
  noOfEmployess: {
    required: "Select the Number of Employees",
  },
  haveSSO: {
    required: "Select an option",
  },
  domainName: {
    required: "Domain Name is required",
    rule: "Please enter a valid domain address"
  },
  firstName: {
    required: "Enter the First Name",
    rule:"Invalid input. Please follow the required format"
  },
  lastName: {
    required: "Enter the Last Name",
    rule:"Invalid input. Please follow the required format"
  },
  email: {
    required: "Email is Required",
    emailRules:
      "Please enter a valid email address. The format should be user@example.com",
  },
  phone: {
    phoneNumberRules: "Enter the 10 digit Mobile Phone",
  },
  addressLine1: {
    required: "Enter Address Line 1",
  },
  addressLine2: {
    required: "Enter Address Line 2",
  },
  zip: {
    zipRules: "Enter 5 Digit zip code",
  },
  state: {
    required: "Enter the State",
  },
  country: {
    required: "Enter the Country",
  },
  cityList: {
    required: "Select City",
  },
  city: {
    required: "Enter the City",
  },
  selectPlan: {
    required: "Select the Plan",
  },
  knowAbout: {
    required: "Select an option",
  },
  saFirstName: {
    required: "Enter the First Name",
    rule:"Invalid input. Please follow the required format"
  },
  saLastname: {
    required: "Enter the Last Name",
    rule:"Invalid input. Please follow the required format"
  },
  saEmail: {
    required: "Email is Required",
    emailRules:
      "Please enter a valid email address. The format should be user@example.com",
  },
  saPhone: {
    phoneNumberRules: "Enter the 10 digit Mobile Phone",
  },
  currentProfessional:{
    message:"Email should different from current Professional email"
  },
  dob: {
    required: "Enter Date of Birth",
  }
};
