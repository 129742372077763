export const accountType=[
    {
        id:"Checking Account",
        label:"Checking Account"
    },
    {
        id:"Savings Account",
        label:"Savings Account"
    },
    {
        id:"Money Market Account",
        label:"Money Market Account"
    },
]