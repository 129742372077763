import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import AttorneyTitleBar from "../../components/attorney/AttorneyTitleBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  client_add_bank_details,
  client_LandingPage,
  client_view_bank_details,
} from "../../constants/routes";
import AgGrid from "../../components/table/AgGrid";
import { bankDetailsLabel, clientTitle } from "../../constants/client/labels";
import {
  useGetAllBankDetailsQuery,
  useRequestAddBankDetailsMutation,
} from "../../slices/wireTransferApiManagementSlice";
import Loader from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import BankShareConfirmationPopup from "../../components/modal/BankShareConfirmationPopup";

export default function ClientRequestBankDetailsTablePage({ refetchData }) {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const [rowData, setRowData] = useState([]);
  const [selectedRow, setSelectedRow] = useState("");
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [shareData, setShareData] =  useState({})
  const ssoResponse =
    sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse);
  const isSSOLogin = ssoResponse
    ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful
    : false;

  const { data, isLoading } = useGetAllBankDetailsQuery(userInfo?.userId, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (data && Array.isArray(data?.data)) {
      setRowData(data?.data);
    } else {
      setRowData([]);
    }
  }, [data]);

  let empnameWidth = 250;
  let empnameMinWidth = 200;
  if (window.innerWidth < 576) {
    empnameWidth = 100;
    empnameMinWidth = 140;
  }

  const [items] = useState([
    {
      id: "item",
      link: client_LandingPage,
      content: clientTitle,
      active: false,
    },
  ]);

  const getRowStyle = () => {
    return { borderBottom: "unset" };
  };

  const GridFullName = (params) => {
    const { value } = params;

    if (value) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
        >
          <div className="truncate-tooltip grid-firm-name-anchor">{value}</div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div className="truncate-tooltip grid-firm-name-anchor">{value}</div>
      );
    }
  };

  const viewBankDetails = (event) => {
    navigate(client_view_bank_details, {
      state: {
        id: event?.data,
        userId: userInfo?.userId,
        page: "bankDetailsList",
      },
    });
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      suppressMovable: false,
      sortable: false,
      wrapHeaderText: true,
      wrapText: true,
      headerClass: "custom-header",
      resizable: false,
      lockPosition: true,
      filter: false,
      // minWidth: 140,
    };
  }, []);

  const columnDefsNonSSO = [
    {
      width: 75,
      minWidth: 75,
      cellRenderer: (params) => {
        const isChecked = selectedRow === params.node.data.bankDetailId;
        return (
          <div className="grid-center-aligned-text">
            <input
              type="radio"
              name="rowSelection"
              id={params.node.data.bankDetailId}
              checked={isChecked}
              onChange={(e) => handleRowSelection(e, params)}
            />
          </div>
        );
      },
      pinned: "left",
    },
    {
      headerName: bankDetailsLabel.accountName,
      field: "accountNumber",
      width: empnameWidth,
      minWidth: empnameMinWidth,
      cellRenderer: (params) => {
        const firstName = params?.data?.firstName || "";
        const lastName = params?.data?.lastName || "";
        const fullName = `${firstName} ${lastName}`;
        return <GridFullName value={fullName} />;
      },
      onCellClicked: (event) => viewBankDetails(event),
    },
    { headerName: "Routing Number", field: "routingNumber", minWidth: 200 },
    { headerName: "Bank Name", field: "bankName", minWidth: 250 },
    { headerName: "Branch Name", field: "branchName", minWidth: 250 },
    { headerName: "Account Type", field: "accountType", minWidth: 180 },
    { headerName: "Account Number", field: "accountNumber", minWidth: 350 },
  ];

  const columnDefsSSO = [
    {
      headerName: bankDetailsLabel.accountName,
      field: "accountName",
      width: empnameWidth,
      minWidth: empnameMinWidth,
      pinned: "left",
      cellRenderer: (params) => {
        const firstName = params?.data?.firstName || "";
        const lastName = params?.data?.lastName || "";
        const fullName = `${firstName} ${lastName}`;
        return <GridFullName value={fullName} />;
      },
    },
    { headerName: "Account Number", field: "accountNumber", minWidth: 350 },
    { headerName: "Bank Name", field: "bankName", minWidth: 180 },
    { headerName: "Branch Name", field: "branchName", minWidth: 180 },
    { headerName: "Account Type", field: "accountType", minWidth: 180 },
  ];

  const handlePrevPage = () => {
    navigate(client_LandingPage, {
      state: {
        id: userInfo?.firmTypeId,
      },
    });
  };

  const handleAddBankDetails = () => {
    navigate(client_add_bank_details, {
      state: {
        id: userInfo?.firmTypeId,
        page: "bankDetailsList",
        transactionId : transactionId
      },
    });
  };

  const handleRowSelection = (e, params) => {
    setSelectedRow((prev) =>
      prev !== params.node.data.bankDetailId
        ? params.node.data.bankDetailId
        : ""
    );
    setShareData(params.node.data)
  };

  const isButtonDisabled = rowData.length >= 5 || rowData.length === 5;
  const isNotificationPage = (location?.state?.page === "requestBankDetails" || location?.state?.page === "bankDetailsList");

  const [requestShareBankDetail, { isLoading: requestShareBankDetailLoading }] =
    useRequestAddBankDetailsMutation({
      skip: !isNotificationPage,
    });

  let transactionId;
  if (location?.state?.page === "requestBankDetails") {
    const apiUrl = location?.state?.id[0]?.apiUrl;
    const urlObj = new URL(apiUrl);
    const queryParameters = new URLSearchParams(urlObj.search);
    transactionId = queryParameters.get("transactionId");
  }

  const handleShare = async () => {
    if (!selectedRow) {
      toast.error("Please select a bank account before sharing.");
      return;
    }
    setShowModal(true);
  };

  const onClickYes = async () => {
    setShowModal(false);
    let transaction_id
    transaction_id = transactionId ? transactionId : location?.state?.transactionId
    const transformData = {
      firstName: shareData?.firstName,
      lastName: shareData?.lastName,
      branchName: shareData?.branchName,
      bankName: shareData?.bankName,
      accountType: shareData?.accountType,
      accountNumber: shareData?.accountNumber,
      swiftCode: shareData?.swiftCode,
      routingNumber: shareData?.routingNumber,
      bankDetailId: shareData?.bankDetailId,
    };
    if (isNotificationPage) {
      try {
        const res = await requestShareBankDetail({
          user: {
            transactionId: transaction_id,
            userId: userInfo?.userId,
          },
          data: transformData,
        }).unwrap();
        if (res?.successful) {
          toast(
            <div>
              <div className="upload mt-2">{res?.message}</div>
            </div>,
            {
              autoClose: true,
              closeOnClick: false,
              theme: "light",
              closeButton: true,
              hideProgressBar: true,
              className: "custom-toast-video-sigining",
            }
          );
          if (userInfo?.role === "CLI") {
            navigate(client_LandingPage);
          }
          refetchData();
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error(err?.data?.message || err.errorMessage);
      }
    }
  };

  const isShareButtonDisabled = !selectedRow;

  const handleCancelNavigation = () => {
    navigate(client_LandingPage);
  };

  return (
    <>
      <div
        className="superadmin-container attorney-edit-client-page"
        data-testid="na-firmadmin-addclient"
      >
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-0 px-lg-4 px-md-4 wire-transfer"
            data-testid="sa-addemployee-page"
          >
            <AttorneyTitleBar
              items={items}
              hasButton={!isSSOLogin}
              buttonText={bankDetailsLabel.addBankAccount}
              width="90px"
              title={bankDetailsLabel.sendBankDetails}
              navigateTo={handleAddBankDetails}
              navigateToPrev={handlePrevPage}
              hasBackButton={false}
              buttonDisabled={isButtonDisabled}
            />
          </div>
        </Container>

        <div className="firm-page-outer-container firm-page-add-outer-container">
          <div className="firm-page-container employee-form-container">
            <Container fluid className="px-lg-4">
              <div className="px-2 pb-3 px-lg-4 px-md-4">
                <Row className="m-10 mt-4">
                  <Col lg={12} xs={12}>
                    {isSSOLogin ? (
                      <AgGrid
                        rowData={rowData}
                        columnDefs={columnDefsSSO}
                        defaultColDef={defaultColDef}
                        getRowStyle={getRowStyle}
                      />
                    ) : (
                      <AgGrid
                        rowData={rowData}
                        columnDefs={columnDefsNonSSO}
                        defaultColDef={defaultColDef}
                        getRowStyle={getRowStyle}
                      />
                    )}
                  </Col>
                </Row>
                <div className="d-flex justify-content-center mt-lg-2 mt-md-2">
                  <Button
                    className="custom-button btn-nobg mx-2 px-5"
                    data-testid="back-button"
                    onClick={handleCancelNavigation}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleShare}
                    className={`custom-button btn-standard ${
                      isShareButtonDisabled ? "btn-disable" : " "
                    } mx-2 px-5`}
                    data-testid="submit-button"
                    disabled={isShareButtonDisabled}
                  >
                    Share
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
      {showModal && (
        <BankShareConfirmationPopup
          setShowCallback={() => setShowModal(false)}
          setYesCallback={onClickYes}
          content={`Are you sure you want to share the bank details?`}
          showModal={showModal}
        />
      )}
      {(isLoading || requestShareBankDetailLoading) && <Loader />}
    </>
  );
}
