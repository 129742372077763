export const phoneNumberRules = /^(?!0+$)\d{12}$/;
export const indphonNumberRules = /^(?!0+$)\d{12}$/;
export const usphonNumberRules = /^(?!0+$)\d{11}$/;
export const deathclaimPhoneNumRules = /^(?!0+$)\d{10}$/;
export const zipRules = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const firmRegisterationNumberRules = /^[0-9a-zA-Z]+$/;
export const npiRules = /^[0-9]{10}$/;
// include space
// export const passwordRules =
//   /^(?=.*[-#$)(%@!_])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?!.*[\s^&*/+<>,.]).{12,}$/;

// including comma
// export const passwordRules =
// /^(?=.*[-#$)(%@!_^\]$*.[\]{}()?\\"!@#%&\\/><':;|_~`+=.])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?!.*[\s,\\]).{12,}$/;

// without comma
export const passwordRules =
  /^(?=.*[-#$)(%@!_^\]$*.[\]()?\\"!@#%&\\/><':;|,_~`+=.])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?!.*[\s\\]).{12,}$/;
export const emailRules = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const verificationCodeRules = /^\d{6}$/;
export const digitsRules = /^[0-9]+$/;
export const alphNumRules = /^(?:[0-9]+[a-z]|[a-z]+[0-9])[a-z0-9]*$/i;
export const idNumberRules =
  /^(?:[0-9]+[a-z]|[a-z]+[0-9]|[0-9]|[a-z])[a-z0-9]*$/i;
//export const alphabetRules=/^[a-zA-Z]+$/;
//exrt const alphabetRules = /^[a-zA-Z ]*$/;
export const alphabetRules = /.*$/;
export const ssnRules = /^(?!0+$)\d{9}$/;
export const domainNameRules =
  /^(?!-)(?![0-9]+$)(?:[a-zA-Z0-9-]{1,63}\.){1,}[a-zA-Z]{2,63}$/;
// export const folderNameRules =
//   /^(?:[a-z])[a-z0-9][^\\/:*?"><|]*$/i;
export const folderNameRules = /^[^/:\\"*?<>|]*$/i;
export const routingNumberRules = /^(?!0+$)\d{9}$/;
export const accountNumberRules = /^(?!0+$)\d{1,30}$/;
export const swiftCode = /^[a-zA-Z0-9]*$/;
export const branchName = /^[\w\s\p{P}\p{S}]{0,80}$/u;
export const bankNameRules = /^[\w\s\p{P}\p{S}]{0,80}$/u;
export const firstNameRules = /.*$/;
//export firstNameRules = /^[\w\s\p{P}\p{S}]{0,30}$/u;

