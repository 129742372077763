import React, { useEffect, useState } from "react";
import { Container, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  firmsuperadmin_LandingPage,
  nafirmsuperadmin_Viewclient,
  nafirmsuperadmin_Editclient,
} from "../../constants/routes";
import Loader from "../../components/Loader/Loader";
import { attorneyTitle, clientDetails } from "../../constants/attorney/lables";
import AttorneyTitleBar from "../../components/attorney/AttorneyTitleBar";
import { useSelector } from "react-redux";
import { useGetClientQuery } from "../../slices/nonAttorneySuperAdminApiManagementSlice";

export default function NAFirmAdminViewClientPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const ssoResponse  =  sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse)
  const isSSOLogin = ssoResponse ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful : false

  const [items, setItems] = useState([
    {
      id: "item-1",
      link: firmsuperadmin_LandingPage,
      content: attorneyTitle,
      active: false,
    },
    {
      id: "item-2",
      link: nafirmsuperadmin_Viewclient,
      content: clientDetails,
      active: true,
    },
  ]);

  const { data, error, isLoading } = useGetClientQuery(location?.state?.id, {
    skip: location?.state?.id === undefined,
  });
  useEffect(() => {
    setItems([
      {
        id: "item-1",
        link: firmsuperadmin_LandingPage,
        content: attorneyTitle,
        active: false,
        state: { id: userInfo?.firmTypeId},
      },
      {
        id: "item-2",
        link: nafirmsuperadmin_Viewclient,
        content: data?.fullName,
        active: true,
        state: { id: data?.clientId },
      },
    ]);
  },[userInfo, data])

  const dateString = data?.DOB || "";
  let formattedDate = "";
  if (dateString) {
    // const date = new Date(dateString);
    // const day = date.getDate();
    // const month = date.getMonth() + 1;
    // const year = date.getFullYear();
    // formattedDate = `${month < 10 ? "0" : ""}${month}-${
    //   day < 10 ? "0" : ""
    // }${day}-${year}`;
    formattedDate = dateString.split('T')[0]
  }

  const handleEditPage = () => {
    navigate(nafirmsuperadmin_Editclient, {
      state: {
        page: "clientEditPage",
        id: location?.state?.id,
        name: data?.fullName,
        prevPage: "viewpage",
      },
    });
  };

  const handlePrevPage = () => {
    navigate(firmsuperadmin_LandingPage, {
      state: {
        id: userInfo?.firmTypeId
      }
    });
  };

  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data}
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <div className="superadmin-container" data-testid="non-attorney-view-client-page">
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-3 px-lg-4"
            data-testid="sa-addemployee-page"
          >
            <AttorneyTitleBar
              items={items}
              hasButton={!isSSOLogin}
              buttonText="Edit"
              width="90px"
              title={data?.fullName ? data?.fullName : "--"}
              navigateTo={handleEditPage}
              navigateToPrev={handlePrevPage}
              hasBackButton={true}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container">
        <Container fluid className="px-lg-4">
          <div className="firm-page-container attorney-view-page employee-form-container px-lg-4 px-3">
            <div className="employee-view-container pt-3">
              <div className="view-details">
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">Name</div>
                    <div className="firm-view-contant">
                      {data?.fullName ? data?.fullName : "--"}
                    </div>
                  </div>
                  
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">Email</div>
                    <div className="firm-view-contant">
                      {data?.email ? data?.email : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                <div className="emp-view-first">
                    <div className="firm-view-label">Mobile Phone</div>
                    <div className="firm-view-contant">
                    {data?.phone ? data?.phone : "--"} 
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">DOB</div>
                    <div className="firm-view-contant">
                    {data?.DOB ? formattedDate : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                
                  <div className="emp-view-first">
                    <div className="firm-view-label">Address</div>
                    <div className="firm-view-contant">
                    {
                      data?.address?.addressLine2
                        ? `${data?.address?.addressLine1}, ${data?.address?.addressLine2}`
                        : data?.address?.addressLine1 ?`${data?.address?.addressLine1}`:"--"
                        }
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">City</div>
                    <div className="firm-view-contant">
                    {data?.address?.city ? data?.address?.city : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">State</div>
                    <div className="firm-view-contant">
                    {data?.address?.state ? data?.address?.state : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">Zip</div>
                    <div className="firm-view-contant">
                    {data?.address?.zip ? data?.address?.zip : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                <div className="emp-view-first">
                    <div className="firm-view-label">Country</div>
                    <div className="firm-view-contant">
                    {data?.address?.country ? data?.address?.country : "--"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {isLoading && <Loader />}
    </>
  );
}
