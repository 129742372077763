import { wireTransferApiSlice } from "./wireTransferApiSlice";

export const wireTransferApiManagementSlice =
  wireTransferApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      addBankDetails: builder.mutation({
        query: (data) => ({
          url: "/account",
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["getBankDetails", "getAllBankDetails"],
      }),
      getAllBankDetails: builder.query({
        query: (userId) => ({
          url: `/accounts?userId=${userId}`,
          method: "GET",
        }),
        providesTags: ["WireTransferBankDetails"],
      }),
      getBankDetails: builder.query({
        query: ({ userId, bankDetailId }) => ({
          url: `/account?bankDetailId=${bankDetailId}&userId=${userId}`,
          method: "GET",
        }),
        providesTags: ["getBankDetails"],
      }),
      deleteBankDetails: builder.mutation({
        query: ({ userId, bankDetailId }) => ({
          url: `/account?bankDetailId=${bankDetailId}&userId=${userId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["getBankDetails", "getAllBankDetails"],
      }),
      shareBankDetails: builder.mutation({
        query: (data) => ({
          url: `/bank-share`,
          method: "POST",
          body: data,
        }),
      }),
      getShareBankDetails: builder.query({
        query: ({ userId, transactionId }) => ({
          url: `/bank-share?transactionId=${transactionId}&userId=${userId}`,
          method: "GET",
        }),
        providesTags: ["getBankDetails"],
      }),
      requestBankDetails: builder.mutation({
        query: (data) => ({
          url: "/account/request",
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["getBankDetails"],
      }),
      requestAddBankDetails: builder.mutation({
        query: ({ data, user }) => ({
          url: `/account/details?transactionId=${user.transactionId}&userId=${user.userId}`,
          method: "POST",
          body: JSON.stringify(data),
          prepareHeaders: (headers) => {
            headers.set("Content-Type", "application/json");
            return headers;
          },
        }),
        invalidatesTags: ["getBankDetails"],
      }),
      getBankTransferDetails: builder.query({
        query: (userId) => ({
          url: `/request/bank-detail?userId=${userId}`,
          method: "GET",
        }),
        providesTags: ["WireTransferBankDetails"],
      }),
      updateAmount: builder.mutation({
        query: (data) => ({
          url: `/account/transaction?transactionId=${data.transactionId}&userId=${data.userId}&amount=${data.amount}`,
          method: "PATCH",
          prepareHeaders: (headers) => {
            headers.set("Content-Type", "application/json");
            return headers;
          },
        }),
        invalidatesTags: ["getBankTransferDetails"],
      }),
      csvGeneration: builder.mutation({
        query: ({ user, data }) => ({
          url: `/csv/generation?fromBankDetailId=${user.bankdetailId}&userId=${user.userId}`,
          method: "POST",
          body: JSON.stringify(data),
          prepareHeaders: (headers) => {
            headers.set("Content-Type", "application/json");
            return headers;
          },
        }),
        invalidatesTags: ["getBankTransferDetails"],
      }),
      csvValidation: builder.mutation({
        query: ({ user, data }) => ({
          url: `/csv/validation?fromBankDetailId=${user.bankdetailId}&userId=${user.userId}`,
          method: "POST",
          body: JSON.stringify(data),
          prepareHeaders: (headers) => {
            headers.set("Content-Type", "application/json");
            return headers;
          },
        }),
        invalidatesTags: ["getBankTransferDetails"],
      }),
      getOwnBankDetails: builder.query({
        query: (userId) => ({
          url: `/accounts?userId=${userId}`,
          method: "GET",
        }),
        providesTags: ["WireTransferBankDetails"],
      }),
    }),
  });

export const {
  useGetAllBankDetailsQuery,
  useGetBankDetailsQuery,
  useAddBankDetailsMutation,
  useDeleteBankDetailsMutation,
  useShareBankDetailsMutation,
  useGetShareBankDetailsQuery,
  useLazyGetShareBankDetailsQuery,
  useRequestBankDetailsMutation,
  useRequestAddBankDetailsMutation,
  useLazyGetAllBankDetailsQuery,
  useGetBankTransferDetailsQuery,
  useUpdateAmountMutation,
  useCsvGenerationMutation,
  useCsvValidationMutation,
  useGetOwnBankDetailsQuery,
} = wireTransferApiManagementSlice;
