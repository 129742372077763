import React from "react";
import BankDetailsLandingPage from "../../components/WireTransfer/BankDetailsLandingPage";
import AddBankDetails from "../../components/WireTransfer/AddBankDetails";
import { useSelector } from "react-redux";
import { useGetAllBankDetailsQuery } from "../../slices/wireTransferApiManagementSlice";
import Loader from "../../components/Loader/Loader";

export default function PersonalBankDetails() {
  const { userInfo } = useSelector((state) => state.auth);

  const { data, isLoading, refetch } = useGetAllBankDetailsQuery(
    userInfo?.userId,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  let renderComponent;
  if (Array.isArray(data?.data) && data.data.length > 0) {
    renderComponent = <BankDetailsLandingPage refetchData={refetch} />;
  } else {
    if (isLoading) {
      renderComponent = <div>loading</div>;
    } else {
      renderComponent = <AddBankDetails refetchData={refetch} />;
    }
  }

  return (
    <>
      <div
        className="view-people-info-container"
        data-testid="super-admin-render-page"
      >
        {renderComponent}
      </div>
      {isLoading && <Loader />}
    </>
  );
}
