export const validation = {
  firstName: {
    required: "Enter the First Name",
    rule: "Invalid input. Please follow the required format"
  },
  lastName: {
    required: "Enter the Last Name",
    rule: "Invalid input. Please follow the required format"
  },
  routingNumber: {
    required: "Enter the 9 digit Routing Number",
    routingNumberRules: "Routing Number must be a 9 digit number",
  },
  bankName: {
    required: "Enter the Bank Name",
    rule: "Bank Name must be alphabets",
  },
  branchName: {
    required: "Enter the Branch Name",
    rule: "Bank Name must be alphabets",
  },
  accountType: {
    required: "Select Account Type",
  },
  accountNumber: {
    required: "Enter the Account Number",
    accountNumberRules: "Account Number must be between 1 and 30 digits",
  },
  swiftCode: {
    required: "Enter the Swift Code",
    rule: "Swift Code must be alphabets and numbers",
  }
};
