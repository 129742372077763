/***Header logo text***/
export const mvs = "MVS";

/**Firm Landing page **/
//Side bar menu labels
export const organization="Organization"
export const firmDetailsLabel="Firm Details";
export const employeeManagementLabel = "Professional Management";
export const reports="Reports"
export const newOnboardButtonText="New Firm Onboarding"
export const firmLandingText="Langing Page Requirement";
export const ytdText="YTD";
export const addFirmSuccessText="{} successfully registered. Super Admin credentials have been sent to the registered email.";
export const firmOnboardingTitle="Firm Onboarding Form";
export const editFirmSuccessText="Firm details have been updated.";
export const editFirmFailText="Firm updation failed!"
export const clientManagementLabel="Member Management";
export const keyManagementLabel="Key Management";
export const accessControlLabel="Access Control";
export const editProfile="Edit Profile";
export const memberProfile = "Member Profile"
export const notification="Notification";
export const additionalProfTitle = "Upload Documents";
/***Header Profile***/

export const myProfile = "My Profile";
export const changePassword = "Change Password";
export const logout = "Logout";
export const changeAttorney = "Change Professional"
export const personalBankDetailsLabel = "Personal Bank Details";

/***Footer text***/
export const mvsVirtualSafe = "My Virtual Safe";

/****Login****/
export const login = "Login";
export const loginEmail = "Enter E-mail ID";
export const loginPassword = "Enter password";
export const dontHaveAccount = "Don’t have an account?";
export const singUp = "Sign Up";
export const loginForgotPassword = "Forgot Password?";
export const loginButton = "Login";

/****Super User Login****/
export const splogintitle = "Login";
export const sploginEmailLabel = "Enter Email";
export const sploginPasswordLabel = "Enter Password";
export const sploginEmailReqValidation = "Enter Email";
export const sploginEmailValidation = "Invalid Email";
export const sploginpasswordReqValidation = "Enter Password";
export const sploginButton = "Login";
export const sploginDurationValiation="Duration is required";

export const sploginNameRegValidation="Enter Name";
export const sploginNameValidation="Name must be alphabets";
export const sploginfirstNameRegValidation="Enter First Name";
export const sploginfirstNameValidation="Invalid input. Please follow the required format";
export const sploginlastNameRegValidation="Enter Last Name";
export const sploginlastNameValidation="Invalid input. Please follow the required format";
export const sploginphoneNumberValidation = "Enter the 10 digit Mobile Phone";

/***Forgot Pasword***/
export const forgotPassword = "Forgot Password";
export const forgotPasswordText = "Enter your email to retrieve your account";
export const forgotPasswordEmail = "E-mail ID";
export const resetPasswordButton = "Reset Password";
export const forgotPasswordLoginPageLabel="Forgot Password?";
export const forgotPasswordSuccessText="Your password reset was successful. You can now proceed to login account."
/***Reset Pasword***/
export const resetPassword = "Password Reset";
export const resetPasswordText =
  "Enter the Code sent to your mail ID to rest the password";
export const resetPasswordCode = "Enter the given code";
export const dontReciveCode = "If you dont have received the code, click ";
export const resendCode = "Resend Code";
export const in30sec = "in 30S";

/***Creat Account***/
export const createAccount = "Sign Up";
export const phFirstName = "First Name";
export const phLastName = "Last Name";
export const phEmail = "Email";
export const phPhone = "Mobile Phone";
export const phBarId = "Bar Id";
export const phAddressLine1 = "Address Line 1";
export const phAddressLine2 = "Address Line 2";
export const phZip = "Zip";
export const phCity = "City";
export const phState = "State";
export const phCountry = "Country";
export const submitButton = "Submit";
export const enterdetails="Enter the details"
export const sharedDocSuccessText="You have successfully shared the document!"

/* Report Modal Popup */
export const name = "Name";
export const emailId = "Email";
export const invalidEmailReport = "Invalid Email";
export const validation = {
  name: {
    required: "Enter the Name",
    rule: "Name must be alphabets",
   },
  foldername:{
    required:"Enter the Folder Name",
    rule:'Name should start with alphbets and not allowed / : \\ " * ? < > |',
  },
  foldercolor:{
    required:"Folder color is required"
  }
}  
export const additionalProfAccessMsg="If you’re here to upload a member’s documents, please check your mail and initiate the process through the link provided.";

export const countryCodeList = ["us","in"];