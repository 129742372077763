import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
function ModalPopup({
  show,
  setShow,
  heading,
  hasCloseButton,
  hasBottomCloseButton,
  modalClass,
  modalSize,
  titleClass,
  headerClass,
  modalContentElement,
  hasFooter,
  footerClass,
  footerButtonText,
  footerButtonClass,
  onClickCloseNavigation,
  animation,
}) {

  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
   
    if (typeof onClickCloseNavigation === "string") {
      navigate(onClickCloseNavigation);
    } 

    if(typeof onClickCloseNavigation === "function") {
      onClickCloseNavigation();
    }
  };
  return (
    <>
      <Modal
        centered
        contentClassName={modalClass}
        size={modalSize}
        show={show}
        onHide={handleClose}
        animation={animation}
      >
        {hasCloseButton ? (
          <Modal.Header closeButton className={headerClass} data-testid="modal-header-close-button">
            <Modal.Title className={titleClass}>{heading}</Modal.Title>
          </Modal.Header>
        ) : (
          <Modal.Header className={headerClass}>
            <Modal.Title className={titleClass}>{heading}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>{modalContentElement}</Modal.Body>
        {hasFooter && (
          <Modal.Footer className={footerClass}>
            {hasBottomCloseButton && (
              <Button variant="secondary" onClick={handleClose} data-testid="close-button-2">
                Close
              </Button>
            )}
            <Button className={footerButtonClass} onClick={handleClose}>
              {footerButtonText}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalPopup;

ModalPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func,
  heading: PropTypes.string,
  hasCloseButton: PropTypes.bool,
  hasBottomCloseButton: PropTypes.bool,
  modalClass: PropTypes.string,
  modalSize: PropTypes.string,
  titleClass: PropTypes.string,
  headerClass: PropTypes.string,
  modalContentElement: PropTypes.object.isRequired,
  hasFooter: PropTypes.bool,
  footerClass: PropTypes.string,
  footerButtonText: PropTypes.string,
  footerButtonClass: PropTypes.string,
  onClickCloseNavigation: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  animation: PropTypes.bool,
};
