import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import {ReactComponent as Info} from "../../assets/images/Info-Personal-Use.svg"

function BankInformationModal({
  showModal,
  setShowCallback,
  setYesCallback,
  content,
  headerTitle,
  singleOkButton,
  setOkCallback,
  titleClass,
  contentClass,
}) {
  const handleClose = () => setShowCallback(false);
  const handleClickYes = () => setYesCallback(true);
  const handleClickOk = () => setOkCallback(true);
  return (
    <>
      <Modal
        id="modal-popup-container-type-8"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        data-testid="modal-popup-type2"
      >
        <Modal.Header
          className="modal-custom-header mb-0 pb-0"
          closeButton
        ></Modal.Header>
        <Modal.Body className="pt-0">
          <div className="modal-content-container text-center">
            <div className="d-flex justify-content-center mb-4">
              <Info />
            </div>
            <div
              className={
                contentClass ? contentClass : "modal-content-section2 mb-4 pt-2 mx-4"
              }
            >
              {content}
            </div>
            {singleOkButton ? (
              <div className="modal-button-section mt-3 pt-2 mb-4">
                <Button
                  onClick={handleClickOk}
                  className="custom-popup-button btn-yes left-radius right-radius mx-3 mb-3"
                  data-testid="ok-button"
                >
                  Ok
                </Button>
              </div>
            ) : (
              <div className="modal-button-section mt-3 mb-5">
                <Button
                  onClick={handleClickYes}
                  className="custom-popup-button btn-yes left-radius"
                  data-testid="yes-button"
                >
                  Yes
                </Button>
                <Button
                  onClick={handleClose}
                  className="custom-popup-button btn-no right-radius"
                  data-testid="no-button"
                >
                  No
                </Button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

BankInformationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowCallback: PropTypes.func,
  content: PropTypes.string,
  headerTitle: PropTypes.string,
};

export default BankInformationModal;
