
export const validation = {
    firstName: {
        required: "Enter the First Name",
        rule: "Invalid input. Please follow the required format"
      },
      lastName: {
        required: "Enter the Last Name",
        rule: "Invalid input. Please follow the required format"
      },
      dob: {
        required: "Enter Date of Birth",
      },
      email: {
        required: "Email is Required",
        emailRules:
          "Please enter a valid email address. The format should be user@example.com",
      },
    phone: {
      phoneNumberRules: "Enter the 10 digit Mobile Phone",
    },
    npi: {
      npiRules: "Enter 10 Digit NPI number",
    },
    name:{
      required: "Practitioner Name is required",
      rule: "Invalid input. Please follow the required format"
    }
  };
  