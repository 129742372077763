import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  alphabetRules,
  emailRules,
  indphonNumberRules,
  phoneNumberRules,
  usphonNumberRules
} from "../../constants/regExp";
import { Controller, useForm } from "react-hook-form";
import { familysearchPlaceholder } from "../../constants/deathclaim/labels";
import { validation } from "../../constants/deathclaim/validationMessages";
import PhoneInput from "react-phone-input-2";
import { countryCodeList } from "../../constants/labels";

function FamilyMemberModal({ showModal, setShowCallback, setSubmitCallback, onClose, headerTitle }) {
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const schema = yup.object().shape({
          firstName: yup
            .string().trim()
            .required(`${validation.firstName.required}`)
            .matches(alphabetRules, `${validation.firstName.rule}`),
          lastName: yup
            .string().trim()
            .required(`${validation.lastName.required}`)
            .matches(alphabetRules, `${validation.lastName.rule}`),
            email: yup
            .string().trim()
            .required(`${validation.email.required}`)
            .matches(emailRules, `${validation.email.emailRules}`),
            phone: yup
            .string()
            .test(
              "phone-validation",
              `${validation.phone.phoneNumberRules}`,
              function (value) {
                if (countryCode) {
                  if (countryCode === "+91") {
                    return indphonNumberRules.test(value);
                  } else if (countryCode === "+1") {
                    return usphonNumberRules.test(value);
                  } else {
                    return phoneNumberRules.test(value);
                  }
                }
              }
            )
            .required(`${validation.phone.phoneNumberRules}`),
  });

  const {
    register,
    setValue,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);

  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    // if (name === "firstName" || name === "lastName") {
    //   event.target.value = value.slice(0, 255);
    // }
    if (name === "phone") {
        event.target.value = value.slice(0, 10);
      }
  };

  const handleClose = () => {
    handleClear()
    setShowCallback(false)
    setPhoneNumber("")
    onClose()
  };

  const handleClear = () => {
    reset();
    setPhoneNumber("")
  }

  const handleClickSubmit = (formData) => {
    const transformData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      emailId: formData.email,
      phoneNumber: `+${formData.phone}`
    };
    setSubmitCallback(transformData)
    handleClose()
  }

  return (
    <>
      <Modal
        className="family-member-modal"
        id = "family-member-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        data-testid="family-member-search-modal"
      >
        <Modal.Header className="border-0 px-4" closeButton>
          <Modal.Title className="modal-custom-title">
            {headerTitle}
          </Modal.Title>
        </Modal.Header>
        <div className="divider-firm"></div>
        <Modal.Body className="px-3 pb-4">
          <Form
            className="custom-form w-100 type-2"
            onSubmit={handleSubmit(handleClickSubmit)}
          >
            <div className="px-2">
              <div className="modal-content-section">
                <Row className="form-row">
                  <Col>
                    <Row>
                      <Col lg={12} md={12} className="mt-2">
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label htmlFor="firstName" className="required mb-0">
                                {familysearchPlaceholder.firstName}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="firstName"
                                autoComplete="off"
                                {...register("firstName", {
                                  required: true
                                })}
                                className="form-input"
                                maxLength={255}
                                isInvalid={!!errors.firstName}
                                data-testid="firstName"
                                id="firstName"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                                className="input-feedback"
                              >
                                {errors.firstName?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label htmlFor="lastName" className="required mb-0">
                                {familysearchPlaceholder.lastName}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="lastName"
                                autoComplete="off"
                                {...register("lastName", {
                                  required: true
                                })}
                                className="form-input"
                                maxLength={255}                                
                                isInvalid={!!errors.lastName}
                                data-testid="lastName"
                                id="lastName"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                                className="input-feedback"
                              >
                                {errors.lastName?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          </Row>
                          <Row>
                          <Col lg={6} md={6}>
                          <Form.Group className="mb-3">
                      <Form.Label id="emp-email" className="required mb-0" htmlFor="email">
                        {familysearchPlaceholder.email}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="email"
                        name="email"
                        autoComplete="off"
                        {...register("email")}
                        isInvalid={!!errors.email}
                        onBlur = {(e) => e.target.value = e.target.value.trim()}
                        data-testid="email"
                        id="email"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                          </Col>
                          <Col lg={6} md={6}>
                                    <Form.Group className="mb-3">
                                    <Form.Label htmlFor="phone" className="required mb-0">
                                        {familysearchPlaceholder.phone}
                                    </Form.Label>
                                    <Controller
                                      control={control}
                                      name="phone"
                                      render={({ field }) => (
                                        <div
                                          className={
                                            errors.phone
                                              ? "phone-input-invalid-container-modal"
                                              : "phone-input-valid-container-modal"
                                          }
                                        >
                                          <PhoneInput
                                            defaultCountry="us"
                                            onlyCountries={countryCodeList}
                                            placeholder="Enter the phone number"
                                            title=""
                                            value = {phoneNumber}
                                            country={"us"}
                                            defaultValue=""
                                            inputProps={{
                                              required: true,
                                              autoFocus: false,
                                              title: "",
                                            }}
                                            countryCodeEditable={false}
                                            onChangeCapture={handleMaxNumber}
                                            onChange={(value, phone) => {
                                              if (
                                                phone.dialCode === "1" &&
                                                phone.countryCode === "us"
                                              ) {
                                                setCountryCode("+1");
                                                setPhoneNumber(value);
                                              }
                                              if (
                                                phone.dialCode === "91" &&
                                                phone.countryCode === "in"
                                              ) {
                                                setCountryCode("+91");
                                                setPhoneNumber(value);
                                              }
                                            }}
                                          />
                                        </div>
                                      )}
                                    />
                                    <Form.Control
                                      className="form-input input-border-radius-unset input_number"
                                      type="hidden"
                                      name="phone"
                                      value={phoneNumber}
                                      autoComplete="off"
                                      {...register("phone")}
                                      isInvalid={!!errors.phone}
                                      data-testid="phone"
                                      id="emp-phone"
                                    />
                                    <Form.Control.Feedback
                                      data-testid="invalid-feedback"
                                      type="invalid"
                                    >
                                      {errors.phone?.message}
                                    </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                        </Row>
                      </Col>
                    </Row>
                      </Col>
                    </Row>
                    <Row>
                        <Col>
                        <div className="d-flex justify-content-end mt-2">
                        <Button type="submit" className="custom-button btn-standard btn-update" data-testid="submit-button" placeholder="submit-button">
                            Submit
                        </Button>
                        </div>
                        </Col>
                    </Row>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

FamilyMemberModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowCallback: PropTypes.func,
  content: PropTypes.string,
  headerTitle: PropTypes.string,
};

export default FamilyMemberModal;
